@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  height: 100%
}

.main-app-div {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.rotate-icon {
  transform: rotate(-60deg);
  transition: transform 0.5s ease;
}

.rotate-icon-click {
  transform: rotate(60deg);
  transition: transform 0.5s ease;
}

.text1 {
  font-weight: 700;
  z-index: 0;
}

.home-main-div {
  /* width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #E3E3E1;
  position: relative;
  min-height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  border:0;
  vertical-align: baseline;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  margin-left: auto;
  margin-right: auto;

}

.flex-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  text-align: center;
}

.logo-container {
  /* overflow: hidden; */
  height: 120px;
  background-color: #E3E3E1;
  margin-bottom: 0.75rem;
}

.logo-image {
  height: 100%;
  /* border-radius: 100%; */
}

.header {
  color: rgb(0, 0, 0);
  font-size: 29px;
  font-weight: 900;
}

.date-display {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

.cookie {
  color: rgb(95, 99, 104);
  font-size: 10px;
  /* line-height: 16px; */
  text-align: center;
  width: 90%;
}

.button-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.75rem;
}

@media (min-width: 640px) {

  .button-container {
    flex-direction: row;
  }
}

.button {
  border-radius: 9999px;
  min-width: 180px;
  font-weight: 500;
  padding: 10px 0;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  background: transparent;
  transition: background-color 200ms, color 200ms;
}

.button:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

.special-button {
  color: rgb(255, 255, 255);
  background-color: #000000;
}

.special-button:hover {
  color: #000;
  background-color: white;
}

.setting-div {
  z-index: 100;
  padding: 0 0.5rem 0.75rem;
  position: absolute;
  width: 290px;
  top: 30px;
  right: 30px;
  border-radius: 8px;
  background: #cacaca;
  border: 1px solid #f7fdff3f;
}

@media (min-width: 640px) {

  .setting-div {
    width: 350px;
  }
}

.close-icon {
  background-color: transparent;
  transition: background-color 100ms;
  padding: 3px;
  border-radius: 9999px;
  color: rgb(0, 0, 0);
  font-size: 30px;
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  z-index: 1
}

.close-icon:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.setting-text {
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 16px;
  margin-top: 0.75rem;
  font-weight: bold;
}

@media (min-width: 640px) {
  .setting-text {
    font-size: 18px;
  }
}

.settings-icon {
  color: rgb(0, 0, 0);
  font-size: 30px;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  cursor: pointer;
  transition: transform 0.3s ease;
  right: 0.50rem;
}

.setting-section {
  color: rgb(0, 0, 0);
  font-size: 16px;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.setting-section:hover {
  background-color: rgba(67, 64, 64, 0.3);
}

@media (min-width: 640px) {
  .setting-section {
    font-size: 18px;
  }
}

.link-text {
  text-decoration: underline;
  cursor: pointer;
}

@media (min-width: 640px) {

  .header {
    font-size: 45px;
  }
}

@media (min-width: 768px) {

  .header {
    font-size: 50px;
  }
}

.paragraph {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

@media (min-width: 640px) {

  .paragraph {
    font-size: 20px;
  }
}

@media (min-width: 768px) {

  .paragraph {
    font-size: 22px;
  }
}

@media (min-width: 640px) {

  .br-hidden {
    display: block;
  }
}

@media (min-width: 640px) {

  .home-main-div {
    width: 100%;
  }
}

@media (min-width: 768px) {

  .home-main-div {
    width: 100%;
  }
}

/* @media (min-width: 1024px) {

  .home-main-div {
    width: 890px;
  }
}

@media (min-width: 1280px) {

  .home-main-div {
    width: 1000px;
  }
} */

.modal-overlay {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.modal-content {
  max-height: 90%;
  max-width: 500px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;
  overflow-y:scroll;
  overflow-x: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-close {
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 3px;
  border-radius: 50%;
  font-size: 30px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  background-color: transparent;
}

.modal-close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.modal-title {
  color: rgb(0, 0, 0);
  font-size: 25px;
  font-weight: bolder;
}

.modal-body {
  color: #000000;
  font-size: 15px;
  overflow-y:scroll;
  /* text-align: justify; */
  margin-bottom: 15px;
  line-height: 24px;
}

.modal-components {
  color: #000000;
  font-size: 15px;
  max-height: 365px;
  /* text-align: justify; */
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 24px;
}

a {
  color: #346eb7;
  text-decoration: underline;
}

.modal-h2-title {
  font-size: medium;
  font-weight: bold;
  padding-bottom: 15px;
}

.modal-ul-bullets {
  list-style-type: disc;
  padding: 0px 0px 0px 20px;
}

.modal-image {
  /* box-shadow: 0px 0px 10px 2px #e4e4e4; */
  border-radius: 4%;
}

.modal-feedback {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: rgb(0, 0, 0);
}

.feedback-icon {
  font-size: 20px;
  cursor: pointer;
}

.feedback-icon.active {
  color: #007bff;
}

.parent{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.congratulations-container {
  max-width: 550px;
  /* padding-top: 40px; */
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.congratulations-container p {
  text-align: center;
  color: black;
  font-weight: bold;
  margin-bottom: 8px;
}

.congratulations-container p .big-text {
  font-size: 30px;
}

.congratulations-container p .big-text-md {
  font-size: 40px;
}

.congratulations-container p .stats-text {
  font-size: 18px;
  margin-bottom: 60px;
}

.congratulations-container p .stats-text-sm {
  margin-bottom: 80px;
  font-size: 24px;
}

.stats {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid rgba(127, 127, 127, 0.45);
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 30px;
}

.stats div {
  min-width: 80px;
  text-align: center;
  color: black;
}

.stats p.large-text {
  font-size: 35px;
  font-weight: bold;
}

.stats p.large-text-sm {
  font-size: 3rem;
}

.stats p.small-text {
  font-size: 17px;
}

.stats p.small-text-sm {
  font-size: 1rem;
}

.share-button {
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 99px;
  background-color: #06bf66;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.share-icon {
  display: inline;
}

@media (min-width: 640px) {
  .congratulations-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 768px) {
  .congratulations-container p.big-text {
    font-size: 40px;
  }

  .congratulations-container p.stats-text {
    font-size: 24px;
  }

  .stats p.large-text {
    font-size: 48px;
  }

  .stats p.small-text {
    font-size: 20px;
  }
}

.quiz-img-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.quiz-img {
  height: 100vh;
}

.quiz-img-div-Landscape {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.exclamation-icon {
  position: absolute;
  top: 7px;
  left: 10px;


  font-size: 35px;
  align-items: center;
  padding: 7px;
  background: white;
  border-radius: 30px;
  /* border-radius: 99px; */
  color: black;
  top: 3px;
  left: 3px;
  transition: opacity 0.2s;
  z-index: 2;
  /* height: 52px; */
  /* width: 52px; */
  cursor: pointer;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: rgba(23, 78, 166, 0.4) 3px 3px 0px;
}

.exclamation-icon:hover {
  background: #ededed;
}

.exclamation-icon-mobile {
  font-size: 35px;
  align-items: center;
  padding: 7px;
  background: white;
  border-radius: 30px;
  /* border-radius: 99px; */
  color: black;
  position: fixed;
  top: 3px;
  left: 3px;
  transition: opacity 0.2s;
  z-index: 1;
  /* height: 52px; */
  /* width: 52px; */
  cursor: pointer;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: rgba(23, 78, 166, 0.4) 3px 3px 0px;
}

.exclamation-login {
  color: #000000;
  font-size: 15px;
  max-height: 365px;
  /* text-align: justify; */
  margin-top: 15px;
  /* margin-bottom: 15px; */
  line-height: 24px;
}



.timer-div {
  display: flex;
  justify-content: center;
  position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center;
  top: 7px;
  align-items: center;
  z-index: 1;
  border-radius: 16px;
  color: rgb(32, 33, 36);
    /* margin-top: 3px; */
}

.timer-div-mobile {
  display: flex;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  align-items: center;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  top: 7px; /* Position from the top */
  /* right: 50%; */
  /* right: 50vw; Center horizontally in the viewport */
  
  z-index: 1000; /* Higher value to ensure visibility */
  /* width: 10vw; Fixed width relative to the viewport width */
  /* height: 5vh; Fixed height relative to the viewport height */
  /* transform: scale(1) !important; Prevent scaling on zoom */

    color: rgb(32, 33, 36);
    margin-top: 3px;
    
}

.timer-display {
  font-size: 19px;
  color: black;
  background: white;
  z-index: 1000;
  /* padding: 5px 26px; */
  /* border-radius: 99px; */
  font-weight: 500;
  border-radius: 16px;
  transition: opacity 0.2s;
  padding: 3px 9px;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: rgba(23, 78, 166, 0.4) 3px 3px 0px;
}

.timer-display {
  color: black;
  transition: all 0.2s ease-in-out;
}

.pulse-flash {
  color: red;
  animation: pulse 1s infinite, flash 0.5s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.6;
  }
}



.skip-btn {
  position: absolute;
  top: 7px;
  right: 10px;
  cursor: pointer;

  padding: 3px 12px;
  font-size: 18px;
  align-items: center;
  font-weight: 400;
  /* padding: 4px; */
  background: white;
  border-radius: 16px;
  /* border-radius: 99px; */
  color: black;
  /* position: fixed; */
  /* top: 3px; */
  /* right: 3px; */
  transition: opacity 0.2s;
  z-index: 1;
  /* height: 52px; */
  /* width: 52px; */
  cursor: pointer;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: rgba(23, 78, 166, 0.4) 3px 3px 0px;
}

.skip-btn-mobile {
  padding: 3px 12px;
  font-size: 18px;
  align-items: center;
  font-weight: 400;
  /* padding: 4px; */
  background: white;
  border-radius: 16px;
  /* border-radius: 99px; */
  color: black;
  position: fixed;
  top: 3px;
  right: 3px;
  transition: opacity 0.2s;
  z-index: 1;
  /* height: 52px; */
  /* width: 52px; */
  cursor: pointer;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: rgba(23, 78, 166, 0.4) 3px 3px 0px;

}

.skip-btn:hover {
  background: #ededed;
}

.skip-btn-mobile:hover {
  font-size: 16px;
  background: #4f4f4fb2;
}

.good-job-btn {
  font-size: 16px;
  color: white;
  background: #06BF66;
  padding: 6px 20px;
  border-radius: 99px;
  cursor: pointer;
  z-index: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.next-time-btn {
  font-size: 16px;
  color: white;
  background: #CD0000;
  padding: 6px 20px;
  border-radius: 99px;
  cursor: pointer;
  z-index: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.option-shadow {
  box-shadow: 0px 4px 0px #6c6c6c40;
}

.congratulation-header {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .congratulation-header {
    font-size: 40px;
  }
}

.stats {
  font-size: 18px;
  margin-bottom: 10px;
}

@media (min-width: 640px) {
  .stats {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .stats {
    font-size: 24px;
  }
}

.question-div {
  max-width: 420px;
  padding: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  height: 100vh;
}

@media (min-width: 768px) {
  .question-div {
    max-width: 559px;
  }
}

.question-text {
  font-size: 28px;
  text-align: start;
  font-weight: bold;
  margin-top: 2rem;
}

.question-full-text {
  font-size: 28px;
  text-align: start;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 50px;
}

.option-div {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  /* margin-left: 5px; */
  margin-bottom: 1.75rem;
  border-radius: 9999px;
  border: 1px solid black;
  color: black;
  cursor: pointer;
  box-shadow: 0px 5px 1px #66666640;
}

.single-option {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 1.75rem;
  border-radius: 9999px;
  cursor: pointer;
  color: #000;
}

.option-black {
  border: 1px solid black;
  box-shadow: 0px 5px 2px #66666640;
}

.option-green {
  color: white !important;
  background: #06BF66;
  cursor: not-allowed;
  border: 1px solid #06BF66;
  box-shadow: 0px 5px 1px #66666640;
}

.option-red {
  color: white !important;
  background: #CD0000;
  cursor: not-allowed;
  border: 1px solid #CD0000;
  box-shadow: 0px 5px 1px #66666640;
}

.option-gray {
  color: rgb(0, 0, 0) !important;
  background: transparent;
  border: 1px solid black;
  box-shadow: 0px 5px 1px #66666640;
}

/* asdfasfasdf */
.zoom-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 500px;
  /* Adjust based on your needs */
  border: 1px solid black;
  /* For visibility */
  user-select: none;
  /* Prevents selecting text on drag */
}

.zoom-container img {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform-origin: center;
}




/* ------ */

.see-result-mainDiv {
  /* background-color: #06bf66; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(127, 127, 127, 0.45);
}


.see-result-mainP {
  font-size: 17px;
  font-weight: 400;
  padding: 8px;
  text-align: start;
}

.congratulationModalMain {
  z-index: 100;
  position: absolute;
  width: 100%;
  max-width: 500px;
  height: 92%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #ffffff;
  border: 2px solid #b9b9b9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3%;

}


@media (max-width: 600px) {
  .congratulationModalMain {
    max-width: 88%;
  }

  .all-quiz-containerDiv {
    padding-inline: 20px;
  }

}

.slider-center {
  width: 100%;
  max-width: 500px;
  height: 100%;
  padding: 20px;
  position: relative;
}

.congratulationModal {
  margin-top: 30px;
  /* margin-inline: 20px; */
  padding: 20px;
}

.congratulation-modal-text1 {
  font-size: 25px;
  font-weight: 700;
  padding-bottom: 25px;
}

.congratulation-modal-text {
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 25px;
}

.congratulation-percentage {
  font-size: 52px;
  font-weight: 500;
  padding-bottom: 25px;
  color: #767676 !important;
}

.congratulation-bottom-text-mainDiv {
  display: flex;
  justify-content: center;
}

.congratulation-bottom-text {
  font-size: 18px;
  font-weight: 400;
  color: #797979 !important;
  /* width: 70%; */
}

.arrow-right {
  position: fixed;
  right: 0px;
  top: 50%;
  font-size: 35px;
  color: #5c5c5c;
  cursor: pointer;
  z-index: 1;
}

.arrow-right:hover {
  border-radius: 99px;
  background: #ececec;
  z-index: 999;
}

.arrow-left {
  position: fixed;
  left: 0;
  top: 50%;
  font-size: 35px;
  color: #5c5c5c;
  cursor: pointer;
  z-index: 1;
}

.arrow-left:hover {
  border-radius: 99px;
  background: #ececec;
  z-index: 999;
}

.second-slider-title {
  text-align: start;
  font-size: 20px;
  font-weight: 700 !important;
  padding-bottom: 15px;
  color: #898989 !important;
}

.second-slider-title-bottom-text {
  text-align: left !important;
  font-size: 28px;
  width: 400;
  padding-left: 15px;
  margin-bottom: 25px;

}

.second-slider-quiz-question1 {
  text-align: left !important;
  font-size: 22px;
  font-weight: 700 !important;
  padding-left: 15px;
}


.all-quiz-containerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
}

.quiz-option-mainDiv {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  width: 100%;
  padding: 10px 20px;
  border-radius: 99px;
  border: 2px solid #BABABA;
}

.percentageDiv {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: #a3a3a3;
  opacity: 30%;
  border-radius: 99px;
}

.percentageDiv-Green {
  position: absolute;
  left: 0;
  height: 100%;
  opacity: 40%;
  background-color: #EDEDEDBF;
  border-radius: 99px;
  z-index: 1 !important;
}

.quiz-option-mainDivGreen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  width: 100%;
  padding: 12px 20px;
  border-radius: 99px;
  background-color: #06BF66;
  color: #000000;
  position: relative;
  z-index: 1 !important;
}

.quiz-option-background-parenting {
  background-color: #EDEDED;
  width: 50%;
  padding-inline: 20px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  border-radius: 16px;

}

.quiz-option {
  font-size: 16px;
  font-weight: 700 !important;
  color: #7A7A7A;
  z-index: 99;
}

.quiz-option-green {
  font-size: 16px;
  font-weight: 700 !important;
  color: black;
}


.quiz-mark {
  font-size: 16px;
  font-weight: 400 !important;
  color: #6c6c6c;
  left: 50%;
  top: 50%;
  transform-origin: center;
}

.sliderMain {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
}

.sliderPagination {
  border: 1px solid #909090;
  border-radius: 99px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin-inline: auto;
  max-width: auto;
  margin-top: auto;
  padding-inline: 15px;
  gap: 5px;
}

.sliderDot {
  width: 8px;
  height: 8px;
  border-radius: 99px;
  background: #D9D9D9;
  border: 1px solid rgb(78, 78, 78);
}

.greenDot {
  width: 8px;
  height: 8px;
  border-radius: 99px;
  background: #74e511;
  border: 1px solid rgb(78, 78, 78);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}



.score-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.score-modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 85%;
  z-index: 1001;
}

.score-modal-header {
  display: flex;
  justify-content: flex-end;
}

.score-close-button {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.score-modal-body {
  margin-top: 10%;
}

/* Login Modal Styles */
.login-modal-content {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  width: 90%;
  margin: auto;
  text-align: center;
  position: relative;
}

.login-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.login-button {
  background-color: black;
  color: white;
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #333;
}
